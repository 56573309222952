import React from "react"

import Layout from "../../../shared/components/layout/layout"
import WhrLogo from "../components/logos/whr-logo"

import "./index.css"

const IndexPage = () => (
  <Layout>
    <div
      style={{
        textAlign: "center",
        maxWidth: 700,
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: 16,
        }}
      >
        <WhrLogo />
      </div>
      <h1>Wonder Horse Ranch</h1>
      <div style={{ marginBottom: 54, marginTop: 48 }}>
        <h4>Mission Statement</h4>
        <p className="quote">
          Creating a mutually therapeutic and collaborative environment for
          people and equine partners, improving the physical and mental
          well-being of both.
        </p>
      </div>
      <div style={{ marginBottom: 54 }}>
        <h4>Vision Statement</h4>
        <p className="quote">
          To create reciprocal therapeutic relationships that honor the
          intelligence of all participants, and advocate for respect and balance
          in the field of equine-assisted services.
        </p>
      </div>
      <a href="mailto:info@wonderhorseranch.org">info@wonderhorseranch.org</a>
    </div>
  </Layout>
)

export default IndexPage
